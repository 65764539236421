'use strict';

angular.module('appApp')
  .factory('Modal', function($rootScope, $modal) {
    /**
     * Opens a modal
     * @param  {Object} scope      - an object to be merged with modal's scope
     * @param  {String} modalClass - (optional) class(es) to be applied to the modal
     * @return {Object}            - the instance $modal.open() returns
     */
    function openModal(scope = {}, modalClass = 'modal-default') {
      var modalScope = $rootScope.$new();

      angular.extend(modalScope, scope);

      return $modal.open({
        templateUrl: 'components/modal/modal.html',
        windowClass: modalClass,
        scope: modalScope
      });
    }

    // Public API here
    return {

      /* Confirmation modals */
      confirm: {

        /**
         * Create a function to open a delete confirmation modal (ex. ng-click='myModalFn(name, arg1, arg2...)')
         * @param  {Function} del - callback, ran when delete is confirmed
         * @return {Function}     - the function to open the modal (ex. myModalFn)
         */
        delete(del = angular.noop) {
          /**
           * Open a delete confirmation modal
           * @param  {String} item   - item or info to show on modal
           * @param  {All}           - any additional args are passed straight to del callback
           */
          return function() {
            var args = Array.prototype.slice.call(arguments),
                item = args.shift(),
                deleteModal;

            deleteModal = openModal({
              modal: {
                type: 'confirmation',
                dismissable: true,
                title: 'Conferma eliminazione',
                item: item,
                buttons: [{
                  classes: 'btn-default',
                  text: 'Annulla',
                  click: function(e) {
                    deleteModal.dismiss(e);
                  }
                }, {
                  classes: 'btn-danger',
                  text: 'Elimina',
                  click: function(e) {
                    deleteModal.close(e);
                  }
                }]
              }
            }, 'modal-danger');

            deleteModal.result.then(function(event) {
              del.apply(event, args);
            });
          };
        }
      },

      new: {
        product(product, cb = angular.noop) {
          return function() {
            var args = product || {
              name: '',
              description: ''
            };

            var hasNameKey = ('name' in product);
            var hasIsNewKey = ('isNew' in product);
            var title = hasNameKey
              ? hasIsNewKey
                ? 'Duplica'
                : 'Modifica'
              : 'Nuovo';
            title += ' prodotto';

            var newModal = openModal({
              modal: {
                type: 'newProduct',
                args: args,
                dismissable: true,
                title: title,
                editting: hasNameKey,
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Salva',
                  click: function(e) {
                    newModal.close(e);
                  }
                }, {
                  classes: 'btn-default',
                  text: 'Indietro',
                  click: function(e) {
                    newModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-primary');

            newModal.result.then(function() {
              cb(args);
            });
          };
        },

        claim(cb = angular.noop) {
          return function() {
            var args = {
              claimType: '',
              claimTarget: ''
            };

            var params = {
              claimTypes : [
                'Fonte di', 'Contenuto elevato di', 'Contiene',
                'Alto contenuto di', 'Contenuto ridotto di', 'Leggero'
              ],
              vitamins: [
                'Vitamina A', 'Vitamina D', 'Vitamina E', 'Vitamina K',
                'Vitamina B1', 'Vitamina B2', 'Niacin', 'Pantothensäure',
                'Vitamina B6', 'Acido folico', 'Biotina', 'Vitamina C'
              ],
              minerals: [
                'Potassio', 'Calcio', 'Magnesio', 'Ferro', 'Rame', 'Iodio',
                'Zinco', 'Manganese', 'Sale', 'Selenio', 'Cromo', 'Molibdeno',
                'Fluoro', 'Cloruro', 'Fosforo'
              ],
              fats: [
                'Acidi grassi Omega-3', 'Acidi grassi monoinsaturi',
                'Acidi grassi polinsaturi', 'Acidi grassi insaturi'
              ]
            };

            var newModal = openModal({
              modal: {
                type: 'newClaim',
                params: params,
                args: args,
                dismissable: true,
                title: 'Nuova dichiarazione',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Salva',
                  click: function(e) {
                    newModal.close(e);
                  }
                }, {
                  classes: 'btn-default',
                  text: 'Indietro',
                  click: function(e) {
                    newModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-primary');

            newModal.result.then(function() {
              var claim =
                (args.claimType && args.claimTarget) ||
                args.claimType === 'Leggero' ?
                  args.claimType + ' ' + args.claimTarget : '';
              cb(claim);
            });
          };
        }
      },

      share: {
        product(cb = angular.noop) {
          return function() {
            var args = { email: '' };

            var shareModal = openModal({
              modal: {
                type: 'shareProduct',
                args: args,
                dismissable: true,
                title: 'Condividi prodotto',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Condividi',
                  click: function(e) {
                    shareModal.close(e);
                  }
                }, {
                  classes: 'btn-default',
                  text: 'Indietro',
                  click: function(e) {
                    shareModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-primary');

            shareModal.result.then(function() {
              cb(args);
            });
          };
        }
      },

      traces: {
        edit(traces, cb = angular.noop) {
          return function() {
            var args = traces || {
              sedano: false,
              crostacei: false,
              uova: false,
              pesce: false,
              glutine: false,
              lupini: false,
              latte: false,
              molluschi: false,
              senape: false,
              fruttiAGuscioNoci: false,
              arachidi: false,
              semiDiSesamo: false,
              soia: false,
              anidrideSolfosaSolfiti: false
            };

            var editModal = openModal({
              modal: {
                type: 'editTraces',
                args: args,
                dismissable: false,
                title: 'Possibili tracce di allergeni',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Salva',
                  click: function(e) {
                    editModal.close(e);
                  }
                }]
              }
            }, 'modal-primary');

            editModal.result.then(function() {
              cb(args);
            });
          };
        }
      },

      labelSizes: {
        show(cb = angular.noop) {
          return function() {
            var showModal = openModal({
              modal: {
                type: 'labelSizesExample',
                dismissable: false,
                title: 'Tipologie etichette',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Chiudi',
                  click: function(e) {
                    showModal.close(e);
                  }
                }]
              }
            }, 'modal-primary');

            showModal.result.then(function() {
              if(cb) {cb();}
            });
          };
        }
      },

      acceptTermsForIngredientSubmission: {
        show(cb = angular.noop) {
          return function() {
            var showModal = openModal({
              modal: {
                type: 'acceptTermsForIngredientSubmission',
                dismissable: false,
                title: 'Termini e condizioni inserimento prodotti (ingredienti / semilavorati / additivi) nel database Primo Label',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Accetta e inserisci',
                  click: function(e) {
                    showModal.close(e);
                  }
                }, {
                  classes: 'btn-default',
                  text: 'Indietro',
                  click: function(e) {
                    showModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-primary');

            showModal.result.then(function() {
              if(cb) {cb();}
            });
          };
        }
      },

      admin: {
        editUser(user, cb = angular.noop) {
          return function() {
            var editModal = openModal({
              modal: {
                type: 'editUser',
                args: user,
                dismissable: false,
                title: 'Modifica utente',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Salva',
                  click: function(e) {
                    editModal.close(e);
                  }
                }, {
                  classes: 'btn-default',
                  text: 'Indietro',
                  click: function(e) {
                    editModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-primary');

            editModal.result.then(cb);
          };
        }
      }

    };
  });
